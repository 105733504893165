import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(faEnvelope, faPhone);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
