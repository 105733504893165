export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Welkom in ons gezellige vakantiehuisje in het hart van Burgh-Haamstede! Geniet van de levendige sfeer op de gezellige terrasjes en de vele restaurants om de hoek. Vanuit het huisje sta je binnen een minuut in het centrum, maar je kunt ook op korte loopafstand kiezen voor het bos en de Zeepeduinen."},{"name":"format-detection","content":"telephone=no"},{"name":"twitter:title","content":"De Weie | Bed en Breakfast in het hart van Burgh-Haamstede"},{"name":"twitter:creator","content":"Bed & Breakfast De Weie"},{"name":"twitter:image","content":"/social-media-image.jpg"},{"name":"og:title","content":"De Weie | Bed en Breakfast in het hart van Burgh-Haamstede"},{"name":"og:image","content":"/social-media-image.jpg"},{"name":"og:site_name","content":"Bed & Breakfast De Weie"}],"link":[],"style":[],"script":[],"noscript":[],"title":"B&B De Weie","htmlAttrs":{"lang":"nl"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true